<template>
  <div class="content">
    <h3>秀圆果科技——数字赋能企业</h3>
    <p>
      江苏秀圆果信息科技有限公司成立于
      2017年年底，位于南京市建邺区奥体中心，致力于传统消费品企业数字化转型，研发了物联网驱动下的企业数字化转型系列产品及SaaS平台，通过物理向数字化资产的转换，通过数字化改造为驱动力，为茶行业、食品与保健品行业提供数字化转型整体解决方案，助力客户企业业务模式和商业模式升级，为客户长期发展赋能。
      公司拥有一支有着丰富经验的物联网和平台软件研发团队，与中茶院、上海交大等机构开展产学研合作，打造了防伪追溯、可视化仓储、防窜货、进销存以及门店经营一体化产品。
    </p>
    <img src="@/assets/img/home1.png" alt="" class="img1" />
    <img src="@/assets/img/home2.png" alt="" class="img2" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .content {
    width: 17.05rem;
    margin: auto;
    h3 {
      text-align: center;
      font-size: 0.72rem;
      font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
      font-weight: bold;
      color: #093c7f;
      margin: 1.02rem 0;
    }
    p {
      margin: 0 0.46rem 0.51rem;
      font-size: 0.72rem;
      font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
      font-weight: 400;
      color: #4f4f4f;
      line-height: 1.02rem;
    }
    img {
      width: 17.05rem;
      height: 8.24rem;
      margin-bottom: 1.02rem;
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .content {
    width: 16.09rem;
    margin: auto;
    position: relative;
    h3 {
      text-align: center;
      height: 0.48rem;
      font-size: 0.4rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #093c7f;
      line-height: 0.48rem;
      margin: 0.7rem 0 0.95rem;
    }
    p {
      width: 8.11rem;
      height: 8.94rem;
      font-size: 0.24rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 0.43rem;
      text-indent: 2em;
      // margin-bottom: 0.43rem;
    }
    .img1 {
      width: 6.78rem;
      height: 3.29rem;
      position: absolute;
      top: 1.43rem;
      right: 0;
    }
    .img2 {
      width: 6.78rem;
      height: 3.29rem;
      position: absolute;
      top: 4.94rem;
      right: 0;
    }
  }
}
</style>
